import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Badge, Collapse, Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import routes from "../routes/index";
import avatar from "../assets/img/avatars/avatar.jpg";
import {
  ADMIN_ROLES,
  PUBLIC_MANAGEMENT_ROLES,
  PUBLIC_ROLES,
} from "../roleConstants";
import { ADMIN_ROUTES, DASHBOARD, MY_LOCKER } from "../routes/constants";
import { STATUSES } from "../mediaStatusConstants";

import brandLogo from "../assets/img/siteImages/kklogo-horz-play.png";
// import brandLogo from "../assets/img/siteImages/kindkatch.png";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to, disabled }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    if (!!disabled && disabled) {
      return null;
    } else {
      return (
        <li className={"sidebar-item " + getSidebarItemClass(to)}>
          <NavLink to={to} className="sidebar-link" activeClassName="active">
            {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
            {name}
            {badgeColor && badgeText ? (
              <Badge color={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            ) : null}
          </NavLink>
        </li>
      );
    }
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = (index) => {
    this.setState((state) => ({
      [index]: !state[index],
    }));
  };

  componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome,
      }));
    });
  }

  render() {
    const {
      hasAutomations,
      sidebar,
      layout,
      userRoles,
      showEmailSenders,
      isParent,
      smsEnabled,
      undreadThreadCount,
    } = this.props;

    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div>
              <a
                className="sidebar-brand"
                href={
                  userRoles.filter((role) =>
                    Object.values(ADMIN_ROLES).includes(role)
                  ).length === 0
                    ? userRoles.filter((role) =>
                        Object.values(PUBLIC_MANAGEMENT_ROLES).includes(role)
                      ).length === 0
                      ? MY_LOCKER
                      : DASHBOARD
                    : ADMIN_ROUTES.CHARITIES
                }
              >
                <img
                  src={brandLogo}
                  className="align-middle text-primary mr-2"
                  style={{ height: "65px", position: "block" }}
                  alt="KindKatch"
                />
              </a>

              <ul className="sidebar-nav">
                {routes.map((category, index) => {
                   if (
                    (!!category.roles &&
                      userRoles.filter((role) => category.roles.includes(role)).length === 0) 
                      //todo: add back in when automations are ready
                    //   ||
                    // // New check for "Automations" when hasAutomations is false
                    // (category.name === "Automations" && !hasAutomations)
                  ) {
                    return null;
                  }

                  return (
                    <React.Fragment key={index}>
                      {category.header ? (
                        <li className="sidebar-header">{category.header}</li>
                      ) : null}

                      {category.children ? (
                        <SidebarCategory
                          name={category.name}
                          badgeColor={category.badgeColor}
                          badgeText={category.badgeData}
                          icon={category.icon}
                          to={category.path}
                          isOpen={this.state[index]}
                          onClick={() => this.toggle(index)}
                        >
                          {category.children.map((route, index) => {
                            if (
                              (route.name !== "Child Organizations" &&
                                route.name !== "Email Sender Accounts") ||
                              (route.name === "Child Organizations" &&
                                isParent !== null &&
                                isParent) ||
                              (route.name === "Email Sender Accounts" &&
                                showEmailSenders !== null &&
                                showEmailSenders)
                            ) {
                              return (
                                <SidebarItem
                                  key={index}
                                  name={route.name}
                                  to={route.path}
                                  badgeColor={route.badgeColor}
                                  badgeText={route.badgeText}
                                  disabled={route.nonSMSOnly && smsEnabled}
                                />
                              );
                            }
                          })}
                        </SidebarCategory>
                      ) : (
                        <SidebarItem
                          name={category.name}
                          to={category.path}
                          icon={category.icon}
                          badgeColor={category.badgeColor}
                          badgeText={
                            category.badgeData === "pendingApprovalCount"
                              ? this.props.pendingApprovalCount
                              : category.badgeData === "unreadMessageCount"
                              ? undreadThreadCount
                              : category.badgeText
                          }
                          disabled={category.smsOnly && !smsEnabled}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
            {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <img
                    className="rounded-circle mr-3"
                    src={avatar}
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="media-body">
                    <h5 className="mb-1">Chris Wood</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-success"
                      />{" "}
                      Online
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="feedback-button">
              <a
                href="https://kindkatch.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="button" color="secondary" className="mr-1 mb-1">
                  Feedback / Support
                </Button>
              </a>
            </div>
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect((store) => ({
    hasAutomations: store.dashboard.hasAutomations,
    sidebar: store.sidebar,
    layout: store.layout,
    userRoles: store.user.userRoles,
    showEmailSenders: store.dashboard.showEmailSenders,
    isParent: store.charities.isParent,
    smsEnabled: store.charities?.smsEnabled,
    pendingApprovalCount: store.media.media.filter((medium) => {
      return medium.status === STATUSES.PENDING;
    }).length,
    undreadThreadCount: store.threads.threads.filter((thread) => {
      return thread.isRead;
    }).length,
  }))(Sidebar)
);
