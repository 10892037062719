import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../../components/Loader";
import {
  getAllCharityMediaRequests,
  getCharityChildren,
  getMyCharity,
} from "../../../services/apiActions";
import { Redirect } from "react-router";
import { MEDIA_ROUTES } from "../../../routes/constants";
import BootstrapTable from "react-bootstrap-table-next";
import { shortDateTimeFormatter } from "../../../formatters";
import paginationFactory from "react-bootstrap-table2-paginator";
import { setChildOrganizationFilters } from "../../../redux/actions/mediaActions";

function MediaRequestReports(props) {
  const [loading, setLoading] = useState(true);
  const [metricsByMediaRequest, setMetricsByMediaRequest] = useState([]);
  const [childOrganizations, setChildOrganizations] = useState([]);
  const [hasChildren, setHasChildren] = useState(false);
  const [mediaRequestId, setMediaRequestId] = useState([]);

  useEffect(() => {
    getAllCharityMediaRequests((response) => {
      setMetricsByMediaRequest(
        response.map((row) => {
          return { ...row };
        })
      );

      getMyCharity((charity) => {
        getCharityChildren((response) => {
          var values = response.map((child) => {
            return { value: child.name, label: child.name };
          });

          setHasChildren(values.length > 0);

          values.unshift({
            value: charity.name,
            label: charity.name,
          });

          setChildOrganizations(values);

          props.setChildOrganizationFilters(
            values.map((value) => {
              return value.value;
            })
          );

          setLoading(false);
        });
      });
    });
  }, []);

  let mediaRequestColumns = [
    {
      dataField: "dateSent",
      text: "Scheduled",
      sort: true,
      formatter: shortDateTimeFormatter,
    },
    {
      dataField: "sent",
      text: "Requests",
      sort: true,
    },
    {
      dataField: "views",
      text: "Viewed Request",
      sort: true,
    },
    {
      dataField: "uploads",
      text: "Uploaded",
      sort: true,
    },
    {
      dataField: "emails",
      text: "Emails",
      sort: true,
    },
    {
      dataField: "texts",
      text: "Texts",
      sort: true,
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Redirect
        push
        to={MEDIA_ROUTES.MEDIA_REQUEST_REPORTS_DETAILS.replace(
          ":id",
          mediaRequestId
        )}
      />

      <Row>
        <Col>
          <h1 className="h3 mb-3 float-left">Media Requests</h1>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <BootstrapTable
            bootstrap4
            bordered={true}
            keyField="id"
            rowEvents={{
              onClick: (e, row) => {
                setMediaRequestId(row.id);
              },
            }}
            hover={true}
            rowStyle={{ cursor: "pointer" }}
            defaultSorted={[
              {
                dataField: "dateSent",
                order: "desc",
              },
            ]}
            pagination={paginationFactory({
              paginationSize: 5,
              // alwaysShowAllBtns: true, // Always show next and previous button
              // withFirstAndLast: false, // Hide the going to First and Last page button
              // hideSizePerPage: true, // Hide the sizePerPage dropdown always
              hidePageListOnlyOnePage: true,
              firstPageText: "First",
              prePageText: "Back",
              nextPageText: "Next",
              lastPageText: "Last",
              nextPageTitle: "Next page",
              prePageTitle: "Previous page",
              firstPageTitle: "First page",
              lastPageTitle: "Last page",
              showTotal: true,
              disablePageTitle: true,

              sizePerPageList: [
                {
                  text: "10",
                  value: 10,
                },
                {
                  text: "20",
                  value: 20,
                },
                {
                  text: "All",
                  value: metricsByMediaRequest.length,
                },
              ], // A numeric array is also available. the purpose of above example is custom the text
            })}
            data={metricsByMediaRequest}
            // data={filteredMetricsByMediaRequest()}
            columns={mediaRequestColumns}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default connect(
  (store) => ({
    childOrganizationFilters: store.media.childOrganizationFilters,
    charityName: store.charities.myCharityName,
  }),
  (dispatch) => {
    return {
      setChildOrganizationFilters: (childOrgs) => {
        dispatch(setChildOrganizationFilters(childOrgs));
      },
    };
  }
)(MediaRequestReports);
